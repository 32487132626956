import styles from './Phone.scss';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import gsap from 'gsap';

const Phone = () => {

  const images = ['app_1', 'app_2', 'app_4', 'app_3', 'app_5'];
  const firstImage = images[0];
  const carousel = useRef(null);
  const maxCount = images.length;

  const [count, setCount] = useState(0);
  const [inview, setInview] = useState(false);

  const { ref, inView } = useInView({
    //triggerOnce: true,
    threshold: 0
  });

  useEffect(() => {
    if (inView) {
      setInview(true);
    } else {
      setInview(false);
    }
  }, [inView]);

  useEffect(() => {
    if (inview) {
      const intervalId = setInterval(() => {
        setCount(prevCount => {
          const newCount = prevCount + 1 >= maxCount+1 ? 0 : prevCount + 1;
          
          gsap.to(carousel.current, {
            xPercent: -newCount * 100,
            duration: 0.6,
            delay: 0,
            ease: 'Quad.easeInOut',
            onComplete: () => {
              if(newCount === maxCount) {
                gsap.set(carousel.current, {xPercent: 0})
                setCount(0);
              }
            }
          });
          return newCount;
        });
      }, 4000);
      return () => clearInterval(intervalId);
    }
  }, [inview]); // Dépend uniquement de 'inview' pour gérer l'intervalle

  return (
    <div className='phone-wrapper' ref={ref}>
      <div className='phone-content' ref={carousel}>
          {images.map((logo, idx) => (
          <div key={idx.toString()}>
            <img className='phone-image' src={'img/' + logo + '.jpg'} alt={logo} />
          </div>
          ))}
          <div key="first-image">
            <img className='phone-image' src={`img/${firstImage}.jpg`} alt={firstImage} />
          </div>
      </div>
    </div>
  )
}

export default Phone;