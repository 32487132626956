import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { I18nextProvider } from "react-i18next";
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import global_en from "./translations/en/global.json";
import global_fr from "./translations/fr/global.json";
import global_de from "./translations/de/global.json";
import global_it from "./translations/it/global.json";

// Configuration de i18next
i18next
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false }, // Pas nécessaire pour React car il échappe par défaut
    fallbackLng: "en", // Langue par défaut si la détection échoue
    resources: {
      en: {
        global: global_en
      },
      fr: {
        global: global_fr
      },
      de: {
        global: global_de
      },
      it: {
        global: global_it
      }
    },
    detection: {
      // Options de configuration pour le détecteur de langue
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'], // Où stocker la langue détectée
    },
    debug: false, // Activer le mode de débogage pour voir les logs de détection
  }, (err, t) => {
    if (err) {
      console.error('Error initializing i18next:', err);
    } else {
      //console.log('Language detected:', i18next.language);
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);