
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ListStrapiSection } from '../services/Api'

import Hero from '../components/layouts/Hero';
import Masonry from '../components/layouts/Masonry';
import Brand from '../components/layouts/Brand';
import Footer from '../components/layouts/Footer';

const Gallery = () => {

  const [t, i18n] = useTranslation("global")

  const [galleryPhotos, setGalleryPhotos] = useState([]);

  // Retrieve STRAPI Sections

  function extractMediumImages(photos) {
    const imageArray = [];

    photos.forEach((photo) => {
        const photoData = photo.attributes.photo.data;
        const mediumFormat = photoData.attributes.formats.medium;

        if (mediumFormat) {

          imageArray.push({
            image: process.env.REACT_APP_BMG_STRAPI_API_URL + mediumFormat.url,
            title: photoData.attributes.name.split('.')[0], // Extracting title from the name without extension
            description: "Lorem-ipsum" // Default description, can be customized
          });

        }
    });

    return imageArray;
  }

  const getStrapiSections = async () =>{
    const galleriesResults = await ListStrapiSection('galleries',i18n.language);
  
    const formattedImages = extractMediumImages(galleriesResults);
    //console.log(formattedImages);
      setGalleryPhotos(formattedImages);
  }    

  useEffect(() => {

    // Retrieve API Lists
    window.scrollTo(0, 0);
    getStrapiSections();

  }, [i18n.language])

/*
  useEffect(() => {

    window.scrollTo(0, 0);

    // Retrieve API Lists
    //getStrapiSections();

  }, [])
*/
  console.log(galleryPhotos)

  return (
    <main className="App">

        <Hero title={t("hero.gallery.title")} tagline={t("hero.gallery.tagline")} img='img/photos/young-man_large2.jpg' height='110vh' svg='img/triangle_dark.svg' />

        <Masonry galleryPhotos={galleryPhotos} />

        <Brand />

        <Footer />

    </main>
  );
}

export default Gallery;