
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import Hero from '../components/layouts/Hero';
import ContactForm from '../components/layouts/ContactForm';
import Footer from '../components/layouts/Footer';


const Client = () => {

  const [t, i18n] = useTranslation("global")

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <main className="App">

        <Hero title={t("hero.contact.title")} tagline={t("hero.contact.tagline")} img='img/photos/frame_large_2.jpg' height='110vh' svg='img/triangle.svg' />

        < ContactForm />
        
        <Footer />

    </main>
  );
}

export default Client;