import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import './Jobs.scss';
import Button from '../../ui/Button';
import IconSmall from '../../ui/IconSmall';
import Scroll from '../../ui/Scroll';
import TitleUp from '../../ui/TitleUp';
import Description from '../../ui/Description';
import gsap from 'gsap';
//import { ListJobOffers } from '../../../services/Api';  // Import your API call function

const Jobs = ({ lang, jobOffers }) => {
    const { t, i18n } = useTranslation("global");

    // State for job offers, loading and error
    const [jobOffersArray, setJobOffersArray] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [classArrowLeft, setClassArrowLeft] = useState('job-slider__nav job-slider__left hidden');
    const [classArrowRight, setClassArrowRight] = useState('job-slider__nav job-slider__right hidden');
    const slider = useRef(null);
    const card = useRef(null);
    const [sliderIndex, setSliderIndex] = useState(0);
    const [cardsInView, setCardsInView] = useState(3);

    // Fetch job offers from server when component mounts
    useEffect(() => {
        const fetchJobOffers = async () => {
            setIsLoading(true); // Start loading
            try {
                //const offers = await ListJobOffers(lang); // Fetch job offers
                if (jobOffers && jobOffers.length > 0) {
                    const processedOffers = jobOffers.map(job => {
                        let obj = {};
                        const jobRestric = job.name.substring(0, 3).toLowerCase();

                        obj.name = job.name.toUpperCase();
                        obj.location = job.location;
                        obj.body = job[i18n.language]?.body;

                        // Image assignment based on name prefix
                        switch (jobRestric) {
                            case "eba":
                                obj.image = "./img/jobs/EBACE.jpg";
                                break;
                            case "eph":
                                obj.image = "./img/jobs/ephj.jpg";
                                break;
                            case "wat":
                                obj.image = "./img/jobs/W&W.jpg";
                                break;
                            case "wef":
                                obj.image = "./img/jobs/WEF.jpg";
                                break;
                            case "ome":
                                obj.image = "./img/jobs/OMEGA.jpg";
                                break;
                            case "mon":
                                obj.image = "./img/jobs/MONTREUX.jpg";
                                break;
                            case "ath":
                                obj.image = "./img/jobs/ATHLETISSIMA.jpg";
                                break;
                            default:
                                console.log("default");
                        }
                        return obj;
                    });

                    setJobOffersArray(processedOffers); // Update job offers state
                } else {
                    setJobOffersArray([]); // No job offers available
                }
            } catch (err) {
                setError("Failed to load job offers.");
            } finally {
                setIsLoading(false); // Stop loading
            }
        };

        fetchJobOffers(); // Call the function
    }, [i18n.language,jobOffers]);

    // Handle slider navigation
    const handleNavClick = (direction) => {
        setSliderIndex(prevIndex => {
            const newIndex = direction === 'left' ? prevIndex - 1 : prevIndex + 1;
            return newIndex;
        });
    };

    // Update GSAP animations on sliderIndex or jobOffersArray change
    useEffect(() => {
        if (sliderIndex === 0) {
            setClassArrowLeft("job-slider__nav job-slider__left hidden");

            // Check if slider.current is not null before animating
            if (slider.current) {
                gsap.to(slider.current, 0.6, { x: 0, ease: 'Quad.easeInOut' });
            }
        } else {
            setClassArrowLeft("job-slider__nav job-slider__left");
            setClassArrowRight("job-slider__nav job-slider__right");

            if (sliderIndex === jobOffersArray.length - cardsInView) {
                setClassArrowRight("job-slider__nav job-slider__right hidden");
            }

            // Check if slider.current and card.current are not null before animating
            if (slider.current && card.current) {
                gsap.to(slider.current, 0.6, {
                    x: -(card.current.offsetWidth + 16) * sliderIndex,
                    ease: 'Quad.easeInOut'
                });
            }
        }
    }, [sliderIndex, jobOffersArray.length, cardsInView]);

    // Update cards in view based on window size
    useEffect(() => {
        if (window.innerWidth <= 800) {
            setCardsInView(2);
        }
    }, []);

    if (isLoading) {
        return <p>Loading job offers...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    if (jobOffersArray.length === 0) {
        return <p>No job offers available at the moment.</p>;
    }

    return (
        <section className='width-100 flex job-wrapper'>
            <div className='constrain-width padding-sides'>
                <TitleUp text={t("jobs.title")} />
                <div className='job-description'>
                    <Description text={t("jobs.description")} />
                </div>
            </div>

            <div className='constrain-width job-slider__wrapper'>
                <div className={classArrowLeft} onClick={() => handleNavClick('left')}>
                    <Scroll />
                    <button className='nav-button'></button>
                </div>

                <div className='job-slider'>
                    <div className='job-slider_content' ref={slider}>
                        {jobOffersArray.map((job, index) => (
                            <div className="job-card" key={index} ref={card}>
                                <img src={job.image} alt={job.title} />
                                <div className='job-card_content'>
                                    <h3 className='text-dark'>{job.name}</h3>
                                    <p>{job.location}</p>
                                    <h5>{job.body}</h5>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={classArrowRight} onClick={() => handleNavClick('right')}>
                    <Scroll />
                    <button className='nav-button'></button>
                </div>
            </div>

            <div className='job-cta padding-sides hidden'>
                <IconSmall title={t("jobs.tagline")} description="" icon="download.svg" alignement="left" theme="accent" />
                <Button theme="white" title={t("jobs.button")} animate={true} to="https://example.com" />
            </div>

            <img className='triangle-top' src='img/triangle.svg' alt='' />
            <img className='triangle-bottom' src='img/triangle.svg' alt='' />
        </section>
    );
};

export default Jobs;