
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { ListJobOffers, ListStrapiSection } from '../services/Api'

import Video from '../components/layouts/Video';
import Member from '../components/layouts/Member';
import App from '../components/layouts/App';
import Client from '../components/layouts/Client'
import Priority from '../components/layouts/Priority';
import Services from '../components/layouts/Services';
import Brand from '../components/layouts/Brand';
import Footer from '../components/layouts/Footer';
import Jobs from '../components/layouts/Jobs';


const Home = () => {

  const [t, i18n] = useTranslation("global")

  const [jobOffers, setJobOffers] = useState([]);

  // Retrieve BMG Job Offers

  const getJobOffers = async () =>{
    const JOResults = await ListJobOffers(i18n.language);   
    setJobOffers(JOResults);
  }

  const [standards, setStandards] = useState([]);

  // Retrieve STRAPI Standards

  const getStrapiSections = async () =>{
    const standardsResults = await ListStrapiSection('standards',i18n.language);
    setStandards(standardsResults);
  }


  useEffect(() => {

    window.scrollTo(0, 0);

    // Retrieve API Lists

    getJobOffers();
    getStrapiSections();

  }, [])

  return (
    <main className="App">
      <Video title={t("hero.home.title")} tagline={t("hero.home.tagline")} video='vid/HERO.mp4' height='110vh' svg='img/triangle.svg'/>
      
      <Member />

      <App />

      <Brand />

      <Client />

      <Priority standards={standards} />

      <Services />

      <Jobs jobOffers={jobOffers}/>

      <Footer />

    </main>
  );
}

export default Home;
