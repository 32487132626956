import styles from './Button.scss'
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { gsap } from "gsap";

const Button = ({ theme, title, animate = true, onClick, type = 'button', to = '' }) => {
    const [inview, setInview] = useState(false);
    const navigate = useNavigate();

    const toggleInview = () => {
        setInview(prev => !prev);
    }

    const { ref, inView } = useInView({
        threshold: 1,
        delay: 1000,
    });

    useEffect(() => {
        toggleInview();
        if (inview) {
            setAnimation(true);
        } else {
            setAnimation(false);
        }
    }, [inView]);

    // Button listeners
    const mouseEnter = () => {
        setButtonClassName("button-primary button-text_up button-primary_active button-primary_stroke");
    }
    const mouseLeave = () => {
        setButtonClassName("button-primary button-text_down button-primary_inactive button-primary_stroke");
    }

    const handleClick = (e) => {
        if (onClick) onClick(e);

        if (to) {
            if (to.startsWith('http')) {
                // Open external links in a new tab
                window.open(to, '_blank');
            } else {
                // Handle internal navigation using react-router
                navigate(to);
            }
        }
    }


    // Animation of the button
    const [animation, setAnimation] = useState(false);
    const svg = useRef(null);

    useEffect(() => {
        if (!animate) return;
        const length = svg.current.getTotalLength();
        if (animation) {
            gsap.set(svg.current, {
                strokeDasharray: `0 ${length}`
            });
            gsap.to(svg.current, {
                strokeDasharray: `${length} ${length}`,
                duration: 1,
                ease: 'Quad.easeInOut',
                onComplete: () => setButtonClassName("button-primary button-text_down button-primary_inactive button-primary_stroke")
            });
            gsap.to(text.current, {
                opacity: 1,
                duration: 1,
                delay: 1
            });
        } else {
            setButtonClassName("button-primary button-text_down button-primary_inactive");
            gsap.to(svg.current, {
                strokeDasharray: `0 ${length}`,
                duration: 1,
                ease: 'Quad.easeInOut'
            });
            gsap.to(text.current, {
                opacity: 0,
                duration: 1
            });
        }
    }, [animation]);

    // Changing the text color depending on the theme
    const text = useRef(null);
    const [className, setClassName] = useState("text-dark");
    const [buttonClassName, setButtonClassName] = useState("button-primary");

    useEffect(() => {
        if (theme === "dark") {
            setClassName("text-dark");
        } else {
            setClassName("text-white");
        }
    }, [theme]);

    return (
        <div className='button-wrapper' ref={ref}>
            <button
                type={type} // Set the button type, useful for form submission
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
                onClick={handleClick} // Use handleClick for both navigation and form submission
                className={buttonClassName}
            >
                <h4 ref={text} className={className}>{title}</h4>
                <svg className='stroke' width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <rect ref={svg} x="0" y="0" width="100%" height="100%" stroke="#C9B39B" fill="transparent" strokeWidth="2" />
                </svg>
            </button>
        </div>
    );
};

Button.propTypes = {
    theme: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func, // Define the onClick prop type
    type: PropTypes.string,  // Set button type for 'button' or 'submit'
    to: PropTypes.string, // For navigation if you want it to behave as a link
};

Button.defaultProps = {
    onClick: () => {}, // Default to a no-op function if not provided
    type: 'button', // Default type is button
    to: '', // No default navigation URL
};

export default Button;