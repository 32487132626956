import styles from './Carousel2.scss';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import gsap from 'gsap';

const Carousel2 = ({ images = [] }) => {

  //const images = ['young-man', 'young-woman_4+', 'young-woman'];
  const carousel = useRef(null);
  const maxCount = images.length;

  const [index, setIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [inview, setInview] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0
  });

  useEffect(() => {
    if (inView) {
      setInview(true);
    } else {
      setInview(false);
    }
  }, [inView]);

  useEffect(() => {
    if (inview) {
      const intervalId = setInterval(() => {
        setCount(count => {
          const newCount = count >= maxCount - 1 ? 0 : count + 1;
          const currentImage = carousel.current.children[newCount].children[0]; // Adjust to select the <img> element

          setIndex(index => {
            const newIndex = index + 1;
            //console.log(newCount + " --- " + newIndex);
            // Get the current image
          

          // Set z-index to the current index
          currentImage.style.zIndex = newIndex;
            return newIndex;
          });

          

          // Use gsap to animate the image's opacity
          gsap.set(currentImage, { opacity: 0, scale:1.0 });
          gsap.to(currentImage, {
            opacity: 1,
            scale: 1,
            duration: 1.5,
            ease: 'Quad.easeInOut'
          });

          return newCount;
        });
      }, 4000);
      return () => clearInterval(intervalId);
    }
  }, [inview]);

  useEffect(() => {
    const firstImage = carousel.current.children[0].children[0];
    firstImage.style.opacity = 1;
  }, []);

  return (
    <div className='carousel2-wrapper' ref={ref}>
      <div className='carousel2-content' ref={carousel}>
        {images.map((logo, idx) => (
          <div key={idx.toString()}>
            <img
              className='carousel2-image'
              src={'img/photos/' + logo + '.jpg'}
              alt={logo}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carousel2;