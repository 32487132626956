import styles from './Video.scss';

import React, { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";

import Logo from '../Logo';
import Title from '../../ui/Title';
import Scroll from '../../ui/Scroll';

import { gsap } from "gsap";

const Video = ( { title, tagline, video, height, svg }) => {
    const [t, i18n] = useTranslation("global")
    const videoRef = useRef(null);

    const dynamicStyle = {
        height: height || '100%', // Default to 100px if height is not provided
    };

    useEffect(() => {

        gsap.set(videoRef.current, { opacity: 0})
        gsap.to(videoRef.current, {
            opacity: 0.8,
            duration: 3,
            delay: 2,
        });

    }, [])

    return (
        <section className='video' style={dynamicStyle}>
            <div className='video-wrapper'>

                <video src={video} loop playsInline autoPlay muted ref={videoRef} />

                <div className='video-text flex padding-sides'>
                    <Logo  />
                    <Title text={title} />
                    <Scroll />
                </div>

            </div>
            <img className='triangle-bottom' src={svg} alt=''></img>
        </section>
    )
};
export default Video