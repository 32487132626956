import styles from './IconSmall.scss';
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const IconSmall = ({ title, description = "", icon, alignement, theme, size=false }) => {
  const img = `img/icons/${icon}`;
  const iconAlign = `icon-small__item icon-small__${alignement}`;
  const iconMargin = `icon-small__margin___${alignement}`;
  const alt = `icon ${icon.substring(0, icon.length - 4)}`;

  const iconRef = useRef(null); // Ref for the icon div
  const iconMarginRef = useRef(null); // Ref for the icon margin div

  let h4Align;
  let h5Align;

  switch (theme) {
    case 'dark':
      h4Align = `text-dark text-${alignement}`;
      h5Align = `text-dark text-${alignement}`;
      break;
    case 'accent':
      h4Align = `text-accent text-${alignement}`;
      h5Align = `text-white text-${alignement}`;
      break;
    case 'white':
      h4Align = `text-white text-${alignement}`;
      h5Align = `text-white text-${alignement}`;
      break;
    default:
      console.warn(`Unknown theme: ${theme}`);
  }

  console.log()

  useEffect(() => {
    const elementIcon = iconRef.current; // The target element for the icon GSAP animations
    const elementMargin = iconMarginRef.current; // The target element for the margin GSAP animations

    if (elementIcon) {
      gsap.fromTo(
        elementIcon, // Select the icon-small__icon div
        { rotation: -180, scale: 0 }, // Initial state
        {
          rotation: 0,
          scale: 1, // Target state
          ease: 'power3.out',
          duration: 1.5,
          scrollTrigger: {
            trigger: elementIcon,
            start: 'top+=0 bottom', // Start when the top of the element enters 0px of the bottom of the viewport
            end: 'top+=250 bottom', // End when the top of the element enters 250px of the bottom of the viewport
            toggleActions: 'play reverse play reverse', // Allow reverse animation on scroll up
            scrub: true, // Synchronize animation with scroll position
            markers: false, // Set to true if you want to debug
          },
        }
      );
    }

    if (elementMargin) {
      gsap.fromTo(
        elementMargin, // Select the icon-small__margin div
        { opacity: 0 }, // Initial state
        {
          opacity: 1, // Target state
          ease: 'power3.out',
          duration: 1.5,
          scrollTrigger: {
            trigger: elementMargin,
            start: 'top+=100 bottom', // Start when the top of the element enters 100px of the bottom of the viewport
            end: 'top+=300 bottom', // End when the top of the element enters 300px of the bottom of the viewport
            toggleActions: 'play reverse play reverse', // Allow reverse animation on scroll up
            scrub: true, // Synchronize animation with scroll position
            markers: false, // Set to true if you want to debug
          },
        }
      );
    }

    // Cleanup function to remove the ScrollTrigger on component unmount
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <li className={iconAlign}>
      <div className='icon-small__icon' ref={iconRef}>
        <img src={img} alt={alt} />
      </div>
      <div className={iconMargin} ref={iconMarginRef}>
        <div>
        {size===true ? <p className={h4Align}>{title}</p> : <h4 className={h4Align}>{title}</h4>}
        <h5 className={h5Align}>{description}</h5>
        </div>
      </div>
    </li>
  );
};

export default IconSmall;